<template>
  <div class="vigor">
    <back></back>
    <div class="title p-l-30 p-r-30">{{user.seed}}</div>
    <div class="f-12 p-l-30 p-r-30">我的种子</div>
<!--    <van-icon name="clear" class="close"/>-->
    <div class="logo">
<!--      <video width="100%" height="100%" loop-->
<!--             id="video1" autoplay-->
<!--             :poster="require('../assets/img/vigor.png')"-->
<!--        src="https://f.cdn-static.cn/24546_16396382657832.mp4?attname=ball.mp4"></video>-->
      <img :src="require('../assets/img/seed.png')" width="80%"/>
    </div>
    <div class="p-l-30 p-r-30">
      <div class="item">
        <div class="m-b-10 bold"><van-icon name="warning-o" class="bold"/>&nbsp;&nbsp;艾草种子</div>
        <div>
          「艾草种植」游戏中可流转游戏道具，分为创世种子与普通种子。创世种子通过秒杀抢购获得，普通种子由创世种子繁育获得。用户购买及种植两种种子将获得对应福利。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Back from '../components/Back'
import { mapState } from 'vuex'
import { getWxConfig } from '../../tool'
export default {
  name: 'Vigor',
  components: { Back },
  computed: {
    ...mapState({
      user: state => state.user
    })
  },
  created() {
    getWxConfig('轻松小镇', '种子记录')
  }
}
</script>

<style scoped lang="scss">
.vigor{
  height: 100vh;
  padding-top: 48px;
  color: black;
  position: relative;
  .close{
    position: absolute;
    right: 17px;
    top: 38px;
    font-size: 27px;
    color: #ECECEC;
  }
  .title{
    font-size: 43px;
    color: black;
  }

  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 20px;
  }

  .item{
    height: 250px;
    padding: 40px;
    border-image: linear-gradient(0deg, #FFFFFF, #F9F6F7, #FFFFFF) 1 1;
    background-image: url("../assets/img/seed-down.png");
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 30px;
    overflow: hidden;
  }
}
</style>
